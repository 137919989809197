@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

body {
    font-family: 'Montserrat', sans-serif !important;
}

th {
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2a3539;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
}

p {
    color: #2a3539;
    font-family: 'Montserrat', sans-serif !important;
}

.slick-slider .slick-slide {
    padding: 0 22px;
}

.slick-next {
    background: url('/public/assets/images/arrow-right.svg') center center
        no-repeat !important;
    right: -20px;
    width: 42px !important;
    height: 48px !important;
}

.slick-prev {
    background: url('/public/assets/images/arrow-left.svg') center center
        no-repeat !important;
    left: -20px !important;
    width: 42px !important;
    height: 48px !important;
    z-index: 10;
}

.slick-next::before,
.slick-prev::before {
    display: none;
}

.collapse {
    visibility: visible;
}

@media only screen and (min-width: 1200px) and (max-width: 2500px) {
    .container {
        max-width: 1250px;
    }
}

#w3m-modal {
    z-index: 1000;
}

#cc-main .cm--box .cm__btn--close {
    background-color: white;
    border: none;
}

#cc-main .cm__btn--close svg {
    color: #30363c;
    stroke: #30363c;
}

#cc-main .cm__btn--close:hover svg {
    color: #30363c;
    stroke: #30363c;
}
