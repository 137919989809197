.banner-whitepaper {
    background-size: cover !important;
    height: 400px;
    padding: 175px 100px;
    h2 {
        font-size: 32px;
        font-weight: 400 !important;
        &::before,
        &::after {
            display: none;
        }
    }
}

.title {
    font-size: 24px;
    font-weight: bold !important;
    line-height: 22px;
    font-style: normal;
    text-align: center;
    color: #2a3539;
    margin-bottom: 20px;
}

.desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    text-align: center;
    color: #2a3539;
    margin-bottom: 26px;
}
.btn-download {
    border-radius: 10px;
    font-size: 13px;
    font-weight: bold;
    line-height: 17px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    background: #2a3539;
    padding: 13px 36px;
    &:hover {
        opacity: 0.9;
    }
}

.bull-content {
    background: #f6f6f6;
    padding-top: 65px;
    padding-bottom: 65px;
}

.white-content {
    padding-top: 65px;
    padding-bottom: 65px;
    background: transparent;
}

.accordion {
    background-color: #6fda44;
    color: #2a3539;
    cursor: pointer;
    padding: 25px 50px;
    width: 100%;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 38px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-align: center;
    line-height: 90px;
    &::after {
        font-family: 'FontAwesome';
        content: '\f078';
        float: right;
        font-size: 30px;
    }
}

.accordion-inverse {
    background-color: #2a3539;
    color: #6fda44;
}

.active.accordion {
    &::after {
        font-family: 'FontAwesome';
        content: '\f077';
    }
}

.panel {
    background-color: white;
    overflow: hidden;
    .container {
        padding: 75px 20px;
        margin-top: -4px;
    }
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 679px) {
    .banner-whitepaper {
        .bg-blur {
            padding: 120px 20px;
        }
    }
    .white-content {
        padding: 0;
    }

    .m-t-20 {
        margin-top: 20px;
    }
    .bull-content {
        padding: 0;
    }
    .panel .container {
        background: #f8f8f8;
        padding: 35px 20px;
    }
    .benefits .panel .container {
        padding: 35px 10px;
    }
}
