section#aligned button {
    background-color: #1a1363;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    padding: 5px 20px;
    transition: 0.3s;
    border: none;
}

section#aligned input {
    border: 1px solid #ddd;
    padding: 8px 10px;
    background-color: transparent;
    font-size: 12px;
    text-transform: capitalize;
    color: #333;
    margin-bottom: 15px;
    width: 100%;
}
