.slider-custom {
    .slick-next {
        background: url('../../public/assets/images/angle-circle-right-icon.svg')
            center center no-repeat !important;
        width: 36px !important;
        height: 36px !important;
        right: -15px !important;
    }
    .slick-prev {
        background: url('../../public/assets/images/angel-left-circle.svg')
            center center no-repeat !important;
        width: 36px !important;
        height: 36px !important;
        left: -15px !important;
    }
}

.media-content {
    display: flex;
    align-items: flex-start;
    padding-bottom: 80px;
    gap: 30px;
    .main-content {
        width: 100%;
        .desc-media {
            font-size: 20px;
            line-height: 22px;
            color: #2a3539;
            margin-top: 26px;
            margin-bottom: 20px;
        }
        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
        }
        .user-info {
            display: flex;
            flex-direction: column;
            gap: 7px;
            .name {
                font-size: 16px;
                font-weight: bold;
                line-height: 21px;
                color: #2a3539;
            }
            .sub-info {
                font-size: 12px;
                font-weight: 500;
                line-height: 21px;
                color: #aaaaaa;
            }
        }
        .btn-share {
            border-radius: 10px;
            background: #4bbe1c;
            background: linear-gradient(-146deg, #4bbe1c 0%, #6fda44 100%);
            padding: 10px 24px 10px 18px;
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
            color: #ffffff;
            display: flex;
            align-items: center;
            gap: 14px;
        }
        .media-description {
            border-radius: 10px;
            filter: drop-shadow(0px 4px 7.5px rgba(0, 0, 0, 0.18));
            background: #f7f7f7;
            padding: 14px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 42px;
            .media-info {
                display: flex;
                gap: 18px;
                p {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 22px;
                    color: #2a3539;
                    margin-bottom: 8px;
                }
            }
            .media-desc {
                font-size: 14px;
                font-weight: bold;
                line-height: 22px;
            }
        }
        .sub-title {
            font-size: 20px;
            color: #2a3539;
            text-align: left;
            margin-bottom: 22px;
        }
        .card-items {
            border-radius: 10px;
            filter: drop-shadow(0px 4px 7.5px rgba(0, 0, 0, 0.1));
            background: #ffffff;
            padding: 0;
            img {
                width: 100%;
                height: 172px;
                border-radius: 10px;
            }
            p {
                font-size: 14px;
                font-weight: bold;
                line-height: 18px;
                color: #2a3539;
                margin-top: 16px;
                margin-bottom: 12px;
            }
            .currency {
                display: flex;
                align-items: center;
                margin-left: 10px;
                img {
                    width: 19px;
                    height: 19px;
                }
                p {
                    font-size: 8px;
                    font-weight: 500;
                    color: #212529;
                    margin-left: 7px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            .buy {
                display: flex;
                align-items: center;
                background-color: #6fda44;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 10px;
                margin-top: 15px;
                img {
                    width: 21px;
                    height: 21px;
                }
                button {
                    background: transparent;
                    color: #ffffff;
                    flex: 1;
                    font-size: 15px;
                    font-weight: bold;
                    line-height: 15px;
                }
            }
        }
    }

    .list {
        flex: 1 0 291px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .items {
            display: flex;
            align-items: center;
            gap: 15px;
            flex: 1 0 50%;
            .image-cover {
                position: relative;
                cursor: pointer;
                flex: 1 0 48%;
                .icon-play {
                    position: absolute;
                    top: calc(50% - 23px);
                    left: calc(50% - 23px);
                }

                &.audio-image {
                    flex: none;
                    width: 38px;
                    height: 38px;
                }
            }
            .media-title {
                font-size: 14px;
                font-weight: bold;
                line-height: 18px;
                color: #2a3539;
                margin-bottom: 11px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .media-desc {
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                color: #8e8e8e;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.media-player {
    width: 100%;
    height: 550px;
    // &.audio {
    //   background: linear-gradient(180deg, #52c124 0%, #6fda44 100%);
    // }
}

.logo-media {
    position: absolute;
    top: calc(50% - 122px);
    left: calc(50% - 104px);
    &.spin {
        animation: rotation 8s infinite linear;
    }
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1200px) {
    .media-player {
        width: 780px;
        height: 513px;
    }
    .media-content .list {
        flex: 1 0 200px;
    }
}

@media only screen and (max-width: 992px) {
    .media-player {
        width: 100%;
        height: 529px;
    }
    .media-content {
        flex-direction: column;
        padding-bottom: 20px;
        width: 100%;

        .list {
            flex: none;
        }
    }
    .media-content .list .items {
        flex: none;
    }
    .media-content .list .items .image-cover {
        height: 94px;
        width: 168px;
        flex: none;
    }
    .hide-on-mobile {
        display: none;
    }
    .media-content .main-content .media-description {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .media-player {
        height: 210px;
    }
    .logo-media {
        width: 100px;
        height: auto;
        top: calc(50% - 70px);
        left: calc(50% - 55px);
    }
}

@keyframes rotation {
    from {
        transform: rotate(359deg);
    }
    to {
        transform: rotate(0deg);
    }
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.justify-content-between {
    justify-content: space-between !important;
}
.d-flex {
    display: flex !important;
}

p {
    margin: 0;
}
