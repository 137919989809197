.wrapper-content {
    max-width: 1230px;
    margin: 0 auto;
    padding-top: 40px;
}

@media only screen and (max-width: 1024px) {
    .wrapper-content {
        max-width: 950px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media only screen and (max-width: 679px) {
    .wrapper-content {
        max-width: 400px;
    }
}
