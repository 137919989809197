// .modal-dialog {
//   max-width: 950px !important;
// }

.modal-content {
    border-radius: 20px !important;
    box-shadow: rgb(0 0 0 / 15%) 2.4px 2.4px 3.2px;
}

.close {
    width: 50px !important;
    position: absolute !important;
    right: 0 !important;
    font-size: 36px;
    z-index: 1;
    background: transparent;
}

.modal-body {
    padding: 0 !important;
}

.modal-left-col {
    border-radius: 20px 0 0 20px;
    padding: 0px 60px;
}

.modal-left-col {
    background: #7ad058;
    text-align: center;
    color: #fff;
    padding: 30px 60px;
    height: 100%;
}
.sniser img {
    margin: 20px 0;
}
.sniser img {
    width: 150px;
    margin-bottom: 50px;
}
.modal-right-col {
    border-radius: 20px 20px 20px 20px;
    padding: 21px 70px 0px 50px !important;
}

.modal-right-col {
    background: #fff;
    text-align: center;
    padding: 20px 60px 0px 60px;
}

.sniser-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sniser-logo img {
    width: 75px !important;
    margin-bottom: 5px !important;
}

.sniser-logo img {
    width: 90px;
    margin-bottom: 10px;
}

.sniser-slider-item {
    img {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        object-fit: cover;
        margin-bottom: 30px;
    }
    p {
        color: #fff;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.slick-dots li {
    button {
        &::before {
            color: #dfe1e4 !important;
            font-size: 12px !important;
        }
    }
}

.slick-dots li.slick-active button:before {
    color: #ffffff !important;
}

.desc {
    margin-bottom: 15px;
    line-height: 1.8;
    color: #6b6b84;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.form-group {
    margin-bottom: 10px !important;
}

.login-form .form-group .form-control {
    height: 40px !important;
    border-radius: 10px;
}
.login-form input {
    padding: 0 20px !important;
}

.login-form .form-group .login-btn {
    width: 100%;
    border-radius: 10px;
    background: #6fda44;
    color: #fff !important;
    border: 1px solid #6fda44;
    height: 45px;
}

.login-form .form-group a {
    font-weight: 600;
    color: #6fda44 !important;
    font-size: 18px !important;
}

.forget {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
}

p:last-child {
    margin-bottom: 0;
}

a {
    transition: 0.5s;
    text-decoration: none;
}

.login-form.signup-form .form-control {
    font-size: 15px;
    padding: 0 5px !important;
}

.back {
    display: none;
}
.btnShowsSignUp {
    font-weight: 600;
    color: #6fda44 !important;
    font-size: 18px !important;
    background: transparent;
}

img {
    display: inline-block;
}

@media only screen and (max-width: 992px) {
    .modal-left-col {
        display: none;
    }
    .modal-right-col {
        position: relative;
        bottom: 36px;
        border-radius: 20px 0px 0px 0px;
        padding: 30px 0 !important;
    }
    .close {
        width: 50px !important;
        position: absolute !important;
        left: 10px !important;
        font-size: 18px;
        z-index: 1;
        top: 0;
        font-weight: 700;
    }
    .x {
        display: none;
    }
    .back {
        display: block;
        width: 200px;
        text-align: left;
    }
    .login-form {
        padding: 25px 20px;
    }
}

.modal-notify {
    &__body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 0 16px;
    }
}
